import Vue from "vue";
import { mdiCashPlus } from "@mdi/js";

export default Vue.extend({
  name: "AdminDashComponent",
  components: {
    resumenDotaciones: () => import("@/components/dashboard/dashItems/resumenDotaciones/resumenDotaciones.vue"),
    nuevaOrdenDotacion: () => import("@/components/dotaciones/nuevaOrdenDotacion/nuevaOrdenDotacion.vue"),
    noDispensado: () => import("@/components/NoDispensado/noDispensado.vue"),
  },
  data() {
    return {
      Icons: {
        Dotaciones: mdiCashPlus,
      } as Record<string, string>,
      componentKey: 0,
    };
  },
  methods: {
    render() {
      this.componentKey += 1;
    },
  },
});
